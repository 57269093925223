<template>
  <div class="tags">
    <div class="tags__wrapper">
      <Row class="-m-4" wrap>
        <slot/>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tags',
};
</script>
